.containerCustom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; /* Reduced padding to fit content on smaller screens */
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.overlayCustom {
  background-color: rgba(28, 21, 42, 0.7);
  border-radius: 20px;
  padding: 20px; /* Reduced padding */
  text-align: center;
  width: 60%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Reduced gap between images */
  flex-wrap: wrap;
  margin-bottom: 30px; /* Increased space below image container */
}

.dynamicImageSize {
  width: 100px; /* Set fixed width */
  height: 150px; /* Set fixed height */
  border-radius: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  object-fit: fill; /* Maintain aspect ratio and crop if needed */
  transition: box-shadow 0.3s, transform 0.3s ease;
}

.dynamicImageSize:hover,
.dynamicImageSize:active {
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.4); /* Same shadow effect */
  transform: scale(1.02); /* Same scaling effect */
}

.selectedCard {
  border-color: #a17bdc;
}

.centeredContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px; /* Reduced top margin */
}

.titleCustom {
  font-size: clamp(1.8rem, 3vw, 2.5rem); /* Responsive font size */
  color: white;
  font-family: "Cinzel Decorative", serif;
  margin-bottom: 10px; /* Reduced space below title */
}

.subtitleCustom {
  font-size: clamp(1.1rem, 2.5vw, 1.5rem); /* Responsive font size */
  color: white;
}

.customImageWrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.deleteIcon {
  position: absolute;
  top: -10px;
  right: -10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
  transition: color 0.3s;
}

.deleteIcon:hover {
  color: #a17bdc;
}

.uploadButton {
  padding: 8px 16px; /* Smaller padding for button */
  background-color: #a17bdc;
  border-radius: 5px;
  color: white;
  font-family: "Cinzel Decorative", serif;
  font-size: clamp(1rem, 2vw, 1.2rem); /* Responsive font size */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.uploadButton:hover {
  background-color: #6e5ba8;
}

/* Responsive styling for tablets */
@media (max-width: 900px) {
  .overlayCustom {
    padding: 15px;
    max-width: 90%;
  }
  .dynamicImageSize {
    width: 80px;
    height: 120px;
  }

  .titleCustom {
    font-size: 1.8rem;
  }
  .subtitleCustom {
    font-size: 1.3rem;
  }
  .deleteIcon {
    font-size: 18px;
  }
}

/* Responsive styling for mobile */
@media (max-width: 600px) {
  .overlayCustom {
    padding: 10px;
    width: 75%;
  }
  .dynamicImageSize {
    width: 70px;
    height: 105px;
  }
  .titleCustom {
    font-size: 1.5rem;
  }
  .subtitleCustom {
    font-size: 1.1rem;
  }
  .uploadButton {
    padding: 6px 12px; /* Further reduced padding */
    font-size: 0.9rem; /* Smaller font for mobile button */
  }
  .deleteIcon {
    font-size: 16px;
  }
}
