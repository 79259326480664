.singleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-size: cover;
  background-position: center;
}

.singleCardWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  flex-grow: 1;
  padding-bottom: 80px;
}

.singleCardContainer {
  width: 80vw;
  max-width: 280px;
  aspect-ratio: 2 / 3;
  perspective: 1000px;
  margin-top: 10px;
}

.singleCardImageContainer {
  position: relative;
  width: 100%;
  aspect-ratio: 2 / 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.singleCardImageWrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 2 / 3;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-style: preserve-3d;
  transition: box-shadow 0.3s, transform 0.3s ease;
  cursor: pointer;
}

.singleCardImageWrapper.clickable:hover,
.singleCardImageWrapper.clickable:active {
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.4);
  transform: scale(1.02);
}

.singleCardImage,
.singleCardBackImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 5px;
  transition: opacity 0.6s ease;
}

.singleCardBackImage {
  opacity: 1;
  z-index: 1;
  transform: translateY(0);
}

.singleCardImageWrapper.revealed .singleCardBackImage {
  opacity: 0;
}

@keyframes blurReveal {
  0% {
    opacity: 0;
    filter: blur(10px); /* Start with a strong blur */
  }
  100% {
    opacity: 1;
    filter: blur(0); /* End with no blur */
  }
}

.singleCardImageWrapper.revealed .singleCardImage {
  animation: blurReveal 1.5s ease forwards; /* Apply the simplified blur reveal animation */
}

.singleReversed {
  transform: rotate(180deg);
}

.singleDescriptionWrapper {
  width: 80vw;
  max-width: 320px;
  text-align: center;
  margin-top: 20px;
}

.singleCardName {
  font-size: clamp(1.3rem, 3vw, 2rem);
  color: #ffffff;
  font-family: "Cinzel Decorative", serif;
  font-weight: bold;
  margin: 0;
}

.singleCardDescription {
  font-size: clamp(1rem, 2.5vw, 1.3rem);
  color: #ffffff;
  line-height: 1.5;
}


@media (max-width: 900px) {
  .singleCardContainer {
    width: 60vw; /* Increase width to make the card larger on tablet */
    max-width: 340px; /* Set a larger maximum width for tablet */
  }
  .singleDescriptionWrapper {
    width: 85vw;
  }
}

@media (max-width: 600px) {
  .singleCardContainer {
    width: 90vw;
    max-width: 220px;
  }
  .singleDescriptionWrapper {
    width: 90vw;
  }
  .singleCardName {
    font-size: 1.3rem;
  }
  .singleCardDescription {
    font-size: 1rem;
  }
}


