/* Global styles for full-height content */
body,
html,
#root {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  color: #fff;
  background-color: #1c152a;
  height: 100%;
}
/* Home container */
.homeContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
}

.overlayContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  flex-grow: 1;
}

.overlay {
  background: rgba(28, 21, 42, 0.7);
  padding: 40px;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  text-align: center;
}

/* Title styling with responsive font sizes */
.title {
  font-size: clamp(2.5rem, 5vw, 4.8rem);
  font-weight: bold;
  color: #fff;
  font-family: "Cinzel Decorative", serif;
  margin-bottom: 20px;
}

/* Link styling with increased spacing */
.link-text {
  font-size: clamp(1.3rem, 2.5vw, 2rem);
  color: #fff;
  text-decoration: none;
  margin: 35px 0; /* Increased margin for more space between links */
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-text:hover {
  text-decoration: none;
  color: #a17bdc;
}

.icon {
  margin-right: 8px;
}

/* Back button styling */
.backButton {
  position: fixed;
  top: 20px;
  left: 5px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1em;
  cursor: pointer;
  font-family: "Cinzel Decorative", serif;
  z-index: 1;
}

.backButton:hover {
  color: #a17bdc;
}

.resetButton {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #1c152a;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Cinzel Decorative", serif;
  font-weight: bold;
  font-size: 16px;
  transition: background-color 0.3s;
}

.resetButton:hover {
  background-color: #a17bdc;
}

.saveToJournalButton {
  margin-top: 50px;
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #1c152a;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Cinzel Decorative", serif;
  font-weight: bold;
  font-size: 16px;
  transition: background-color 0.3s;
}

.saveToJournalButton:hover:enabled {
  background-color: #a17bdc;
}

.saveToJournalButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.no-entries-warning {
  text-align: center;
}

.reset-save-buttons {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  text-align: center; /* Center any text */
}

.saveLimitMessage {
  color: white;
  background-color: #a17bdc;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 10px;
  padding: 10px;
}

/* Responsive adjustments for tablets */
@media (max-width: 900px) {
  .overlay {
    padding: 60px;
    width: 70%;
  }
  .title {
    font-size: 3rem;
  }
  .link-text {
    font-size: 1.7rem;
  }
}
/* Responsive adjustments for mobile */
@media (max-width: 600px) {
  .overlay {
    width: 70%;
    padding: 40px;
  }
  .title {
    font-size: 2.5rem;
  }
  .link-text {
    font-size: 1.3rem;
    margin: 25px 0; /* Slightly reduce margin for mobile */
  }
}
